import { inject, Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthService {
  private oauthService = inject(OAuthService);

  private signedInSource = new ReplaySubject<boolean>(1);
  signedIn$ = this.signedInSource.asObservable();

  get userName(): string {
    const claims = this.oauthService.getIdentityClaims();

    return claims ? claims['name'] : '';
  }

  configure(config: AuthConfig): void {
    this.oauthService.configure(config);
  }

  initLogin(): void {
    this.oauthService
      .loadDiscoveryDocumentAndLogin()
      .then(() => this.signedInSource.next(this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken()));
  }

  logout(): void {
    this.oauthService.logOut();
  }
}
