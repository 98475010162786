import { HttpBackend, HttpClient, HttpRequest } from '@angular/common/http';
import { inject, InjectionToken } from '@angular/core';

import { BehaviorSubject, tap } from 'rxjs';

import { AppConfig } from '@ciphr/shared/interfaces';

export const APP_CONFIG = new InjectionToken<BehaviorSubject<AppConfig | null>>('App Config', {
  factory: () => new BehaviorSubject<AppConfig | null>(null),
  providedIn: 'root',
});

export const appConfigInitializerFactory = () => {
  const appConfigSource = inject(APP_CONFIG);
  const httpBackend = inject(HttpBackend);

  const httpClient = new HttpClient({ handle: (req: HttpRequest<void>) => httpBackend.handle(req) });

  return () => httpClient.get<AppConfig>('/assets/app-config.json').pipe(tap((config) => appConfigSource.next(config)));
};
