import { Injectable } from '@angular/core';

import { first } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable()
export class AuthAccessService {
  canActivate = (auth: AuthService) => this.checkAuth(auth);
  canMatch = (auth: AuthService) => this.checkAuth(auth).pipe(first());

  private checkAuth = (auth: AuthService) => auth.signedIn$;
}
