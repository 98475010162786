import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ChrComponentsModule } from '@ciphr-design-system/angular';

import { appConfigInitializerFactory } from '@ciphr/shared/utils';
import { AuthModule } from '@ciphr/auth';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NavigationComponent } from './navigation/navigation.component';
import { uiReducer } from './core/state/ui/ui.reducer';

@NgModule({
  declarations: [AppComponent, NavigationComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot({ ui: uiReducer }),
    ChrComponentsModule.forRoot({
      components: true,
      defaultTheme: 'ciphr-light',
      iconsUrl: '/assets/icons',
      polyfills: true,
    }),
    AuthModule.withConfig({
      redirectUri: window.location.origin,
      scope: 'openid profile email offline_access https://api.recruitment.ciphr.com/.default',
    }),
    AppRoutingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: APP_INITIALIZER, useFactory: appConfigInitializerFactory, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule {}
