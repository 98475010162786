import { APP_INITIALIZER, inject, ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { filter, first, switchMap, tap } from 'rxjs';

import { AuthConfig, OAuthModule } from 'angular-oauth2-oidc';

import { APP_CONFIG } from '@ciphr/shared/utils';

import { AuthService } from './auth.service';
import { AuthAccessService } from './auth-access.service';

const authInitializerFactory = (config?: AuthConfig) => () => {
  const appConfigSource = inject(APP_CONFIG);
  const authService = inject(AuthService);

  return () =>
    appConfigSource.pipe(
      filter(Boolean),
      tap((appConfig) => {
        authService.configure({
          ...appConfig.authConfig,
          ...config,
        });
        authService.initLogin();
      }),
      switchMap(() => authService.signedIn$),
      filter(Boolean),
      first(),
    );
};

@NgModule({
  imports: [HttpClientModule, OAuthModule.forRoot()],
  providers: [AuthAccessService, AuthService],
})
export class AuthModule {
  static withConfig(config: AuthConfig): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [{ provide: APP_INITIALIZER, useFactory: authInitializerFactory(config), multi: true }],
    };
  }
}
