import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectSideNavToggle } from '../core/state/ui/ui.selectors';
import { uiActions } from '../core/state/ui/ui.actions';

@Component({
  selector: 'ciphr-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  private store = inject(Store);

  isOpen$ = this.store.select(selectSideNavToggle);

  handleToggle(): void {
    this.store.dispatch(uiActions.toggleNavigation());
  }
}
